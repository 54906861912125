import React from 'react';

function truncateDecimals(number, digits) {
  var multiplier = Math.pow(10, digits),
    adjustedNum = number * multiplier,
    truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

  return parseFloat((truncatedNum / multiplier).toFixed(digits));
}

export default function CommissionBreakout(props) {
  const { paymentCalculationsToDisplay, selectedPayScale, systemSizeKW, repTenure, product, payscale } = props;
  console.log('totalCommission', selectedPayScale);
  if (paymentCalculationsToDisplay.length === 0) {
    return null;
  }
  let m1 = 0;
  let m1AtSiteAudit = 0;
  const AMOUNT_PER_KW = 100;
  if (repTenure.id === 'Set') {
    // m1 = AMOUNT_PER_KW * systemSizeKW > 1000 ? 1000 : AMOUNT_PER_KW * systemSizeKW;
    if (payscale === 'Retail') {
      m1 = 50 * systemSizeKW;
    } else if (product === 'Mosaic Loan' || product === 'Enfin TPO') {
      m1 = 400;
      m1AtSiteAudit = 600;
    } else if (product === 'Sunrun TPO' || product === 'Goodleap TPO') {
      m1 = 1000;
    }
  }
  if (repTenure.id === 'Close') {
    if (product === 'Mosaic Loan') {
      m1 = 1000;
    }
    if (payscale === 'Retail') {
      m1 = 0;
    }
  }

  if (repTenure.id === 'Self gen') {
    if (product === 'Mosaic Loan') {
      m1 = 2000;
    } else if (product === 'Sunrun TPO' || product === 'Goodleap TPO' || product === 'Enfin TPO') {
      m1 = 1000;
    }
  }

  console.log('selectedPayScale', selectedPayScale);
  let m2 = selectedPayScale.totalCommission * 0.8 - m1 - m1AtSiteAudit;
  let m3 = selectedPayScale.totalCommission * 0.2;
  // M1 exceeds 50% of total commission
  const totalM1 = m1 + m1AtSiteAudit;
  if (totalM1 > selectedPayScale.totalCommission * 0.5 && payscale !== 'Retail') {
    m1 = 0;
    m1AtSiteAudit = 0;
    m2 = selectedPayScale.totalCommission * 0.8;
    m3 = selectedPayScale.totalCommission * 0.2;
  }
  if (payscale === 'Retail' && repTenure.id === 'Set') {
    m2 = 0;
    m3 = 0;
  }

  let m1PaymentTimingDescription = 'SOW Approved';
  if (payscale === 'Retail' && repTenure.id === 'Set') {
    m1PaymentTimingDescription = 'At Sale';
  }

  return (
    <div className="commission-breakout flex-column">
      <div className="flex-row flex-jc-sb">
        <p>Total</p>
        <p>${truncateDecimals(Number(selectedPayScale?.totalCommission), 2)}</p>
      </div>
      <p className="payment-timing">Payment Timing</p>
      <div className="commission-table flex-column">
        {m1AtSiteAudit > 0 && (
          <div className="flex-row flex-jc-sb">
            <p>Survey Pay</p>
            <p>${truncateDecimals(Number(m1AtSiteAudit), 2)}</p>
          </div>
        )}
        <div className="flex-row flex-jc-sb">
          <p>M1 ({m1PaymentTimingDescription})</p>
          <p>${truncateDecimals(Number(m1), 2)}</p>
        </div>
        <div className="flex-row flex-jc-sb">
          <p>M2 (Install 80%)</p>
          <p>${truncateDecimals(Number(m2), 2)}</p>
        </div>
        <div className="flex-row flex-jc-sb">
          <p>M3 (PTO 20%)</p>
          <p>${truncateDecimals(Number(m3), 2)}</p>
        </div>
      </div>
    </div>
  );
}
