import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';

export default function RepTenure(props) {
  const { repTenureValues, repTenure, contractSignDate, handleRepTenure, dealer, userType, payscale, fetchRepTenureVariables, market } = props;
  const [validRepTenureValues, setValidRepTenureValues] = useState(repTenureValues);
  useEffect(() => {
    if (dealer && userType) {
      fetchRepTenureVariables(contractSignDate);
    }
    // console.log(repTenureValues);
  }, []);

  useEffect(() => {
    if (payscale === 'Retail') {
      const NOT_ALLOWED_REP_TENURE = ['50/50'];
      setValidRepTenureValues(repTenureValues.filter((rp) => !NOT_ALLOWED_REP_TENURE.includes(rp.title.trim())));
    } else {
      setValidRepTenureValues(repTenureValues);
    }
  }, [payscale]);

  return (
    <div className="width100">
      <div className="width100">
        {repTenureValues.length > 0 ? (
          <div className="width100">
            <p className="lead-type-label">
              Lead Type<span className="select-one">(select one)</span>
            </p>
            <div className="flex-row-wrap width100 flex-jc-sb">
              {validRepTenureValues.map((rp, i) => {
                return (
                  <Button
                    key={rp.title + rp.value + i}
                    className=""
                    variant={
                      (rp.title == repTenure.id && repTenure.value == rp.value && payscale === 'On Trip') ||
                      (rp.title == repTenure.id && rp.yOnTripValue == repTenure.value && payscale !== 'On Trip')
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => {
                      handleRepTenure(rp, payscale, market);
                    }}
                  >
                    {rp.title.trim()}
                  </Button>
                );
              })}
              {payscale === 'Retail' && (
                <p
                  style={{
                    color: '#aaa',
                    fontSize: '12px',
                  }}
                >
                  <b>Self gen</b> means you set the lead yourself in-store. <b>Close</b> means you closed a lead from a setter in-store.
                </p>
              )}
              {validRepTenureValues.length === 0 && (
                <p
                  style={{
                    color: '#aaa',
                    fontSize: '12px',
                  }}
                >
                  Select a Payscale to see valid Lead Types.
                </p>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
